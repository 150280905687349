module.exports = [{
      plugin: require('../node_modules/gatsby-source-prismic-graphql/gatsby-browser.js'),
      options: {"plugins":[],"repositoryName":"unitedroadwebsite","accessToken":"MC5YbjBpeGhBQUFCNEFkNGhH.Ku-_ve-_ve-_vQpJNu-_vSjvv73vv71v77-977-9XVrvv73vv71zWe-_ve-_ve-_ve-_vT5K77-977-977-977-9UWM","path":"/preview","previews":true,"pages":[{"type":"Vehicle_moves","match":"/:uid","path":"/:uid/unpublished","component":"/opt/build/repo/src/templates/vehicleMove.js"},{"type":"General","match":"/:uid","path":"/:uid/unpublished","component":"/opt/build/repo/src/templates/general.js"},{"type":"Careers","match":"/careers","path":"/careers/unpublished","component":"/opt/build/repo/src/templates/careers.js"},{"type":"About_us","match":"/about","path":"/about/unpublished","component":"/opt/build/repo/src/templates/about.js"}]},
    },{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"gatsby-starter-default","short_name":"starter","start_url":"/","background_color":"#663399","theme_color":"#663399","display":"minimal-ui","icon":"src/images/favicon.png"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-gtag/gatsby-browser.js'),
      options: {"plugins":[],"trackingIds":["G-X95DWS4FS3"],"pluginConfig":{"head":false,"exclude":["/preview/**"]}},
    },{
      plugin: require('../node_modules/gatsby-plugin-facebook-pixel/gatsby-browser.js'),
      options: {"plugins":[],"pixelId":"267910227270094"},
    },{
      plugin: require('../node_modules/gatsby-plugin-hubspot/gatsby-browser.js'),
      options: {"plugins":[],"trackingCode":"7870876","respectDNT":true,"productionOnly":true},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    }]
